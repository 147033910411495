<template>
    <nav>
        <ul class="pagination mb-0 justify-content-center">
            <!-- <li class="page-item" :class="{ 'disabled': previousPage == null }">
                <router-link :to="{ name: viewComponent, query: { page: 1 } }" class="page-link disabled" @click="$event.target.blur()"><span class="d-none d-sm-inline">First</span><span class="d-sm-none">&lt;&lt;</span></router-link>
            </li> -->
            <li class="page-item" :class="{ 'disabled': previousPage == null }">
                <a href="#" class="page-link" @click="navigate(previousPage); $event.target.blur()"><span class="d-none d-sm-inline">Previous</span><span class="d-sm-none">&lt;</span></a>
            </li>
            <li v-for="pageNumber in pageNumbers" :key="pageNumber" class="page-item" :class="{'active': pageNumber == currentPage}">
                <a href="#" class="page-link" @click="navigate(pageNumber); $event.target.blur();">{{pageNumber}}</a>
            </li>
            <li class="page-item" :class="{ 'disabled': nextPage == null }">
                <a href="#" class="page-link" @click="navigate(nextPage); $event.target.blur()"><span class="d-none d-sm-inline">Next</span><span class="d-sm-none">&gt;</span></a>
            </li>
            <!-- <li class="page-item" :class="{ 'disabled': nextPage == null }">
                <router-link :to="{ name: viewComponent, query: { page: lastPage } }" class="page-link disabled" @click="$event.target.blur()"><span class="d-none d-sm-inline">Last</span><span class="d-sm-none">&gt;&gt;</span></router-link>
            </li> -->
        </ul>
    </nav>
</template>

<style scoped lang="scss">
    
</style>

<script>
    export default {
        props: {
            viewComponent: {
                required: true
            },
            currentPage: {
                required: true
            },
            lastPage: {
                required: true
            },
            visiblePages: {
                default: 5
            },
        },
        data() {
            return {
                
            }
        },
        computed: {
            pageNumbers () {
                const visiblePagesThreshold = (this.visiblePages - 1) / 2
                const pageListPadded = Array(this.visiblePages).fill(0);
                var pageList;
            
                // The current page is lower than our threshold, so start at 1.
                if (this.currentPage <= visiblePagesThreshold + 1)
                {
                    pageList = pageListPadded.map((paginationTrigger, index) => {
                        return index + 1;
                    });
                }
                // The current page is closer to the last page than our threshold so we'll be ending on the last page
                else if (this.currentPage >= this.lastPage - visiblePagesThreshold + 1)
                {
                    pageList = pageListPadded.map((paginationTrigger, index) => {
                        return this.lastPage - index
                    });

                    pageList.reverse();
                }
                // Otherwise we're starting our range somewhere in the middle.
                else
                {
                    var startingPage = this.currentPage - visiblePagesThreshold;
                    pageList = pageListPadded.map((paginationTrigger, index) => {
                        return startingPage + index;
                    });
                }

                // Sometimes the above will return pages that are outside of the range, we filter these out.
                return pageList.filter(item => item <= this.lastPage && item >= 1);
            },
            previousPage () {
                return this.currentPage !== null && this.currentPage > 1 ? this.currentPage - 1 : null;
            },
            nextPage () {
                return this.currentPage !== null && this.currentPage < this.lastPage ? this.currentPage + 1 : null;
            },
        },
        methods: {
            navigate: function(pageNumber) {
                var query = {};

                for (const property in this.$route.query) {
                    query[property] = this.$route.query[property];
                }

                query.page = pageNumber;

                this.$router.push({ name: this.viewComponent, query: query});
            }
        },
        mounted () {
            
        },
    }
</script>
