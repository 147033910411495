<template>
    <div class="bg-white">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
                <h1 class="h4 mb-0">Select a practice</h1>
            </div>
        </div>
        <ul v-if="practiceData && practiceData.data.length" class="practice-list mb-4">
            <li v-for="practice of practiceData.data" v-bind:key="practice.id">
                <div class="row align-items-center">
                    <div class="col">
                        <div class="">{{ practice.company_name }}</div>
                    </div>
                    <div class="col d-flex justify-content-end">
                        <div class="">
                            <button class="btn btn-sm btn-secondary" @click="setPractice(practice)">Select</button>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div v-if="practiceData && !practiceData.data.length">
            No practices
        </div>
        <Pagination
            v-if="practiceData && practiceData.data.length"
            v-bind:currentPage="currentPage"
            v-bind:lastPage="lastPage"
            viewComponent="Practices"
        />
    </div>
</template>

<style scoped lang="scss">
    .practice-list
    {
        list-style: none;
        padding: 0px;
        margin: 0px;

        > li
        {
            border-top: 1px solid #EEEEEE;
            padding: 10px 0px;

            strong
            {
                color: $swift-dark-grey;
            }
        }
    }
</style>

<script>
    import api from "@/services/api";
    import Pagination from '@/components/Pagination.vue';

    export default {
        components: {            
            Pagination,
        },
        data() {
            return {
                practiceData: null,
            }
        },
        computed: {
            currentPage () {
                var pageParam = this.$route.query.page;
                return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
            },
            lastPage () {
                if(this.practiceData !== null)
                {
                    return parseInt(this.practiceData.meta.last_page);
                }

                return null;
            },
        },
        methods: {
            moveUp() {
                const html = document.querySelector('html')
                var scrollBehavior = html.style.scrollBehavior;
                html.style.scrollBehavior = 'auto'
                window.scrollTo({
                    top: 0,
                });
                setTimeout(() => {
                    html.style.scrollBehavior = scrollBehavior;
                }, 100);
            },
            fetchPractices: function() {
                this.moveUp();

                api
                .get('/api/v1/practices', {
                    params: {
                        page: this.currentPage,
                    }
                })
                .then(response => {
                    this.practiceData = response.data;
                })
                .catch(() => {
                    console.log('Catch practice error');
                });
            },
            setPractice: function(practice) {
                this.$store.dispatch('setCurrentPracticeId', practice.id);
                this.$store.dispatch('setCurrentPractice');
                this.$router.push('/');  
            },
        },
        mounted () {
            this.fetchPractices();
        },
        watch: {
            currentPage() {
                this.fetchPractices();
            },
        },
    }

</script>
